<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-3">Detalhes da validação</h1>
    <div class="px-3 py-3 border w-2/5">
      <h3 class="text-base font-medium my-1">Campanha: {{ campanha && campanha.nome ? campanha.nome : ''}}</h3>
      <h3 class="text-base font-medium my-1">Total: {{ campanha && campanha.total ? campanha.total : ''}}</h3>
      <h3 class="text-base font-medium my-1 text-green-800">Tem WhatsApp: {{ campanha && campanha.totalEnviado ? campanha.totalEnviado : 0 }}</h3>
      <h3 class="text-base font-medium my-1 text-red-800">Não tem WhatsApp: {{ campanha && campanha.totalFalhas ? campanha.totalFalhas : 0}}</h3>
      <h3 class="text-base font-medium my-1">Login: {{ campanha && campanha.login && campanha.login.nome ? campanha.login.nome : ''}}{{ campanha && campanha.login && campanha.login.email ? ` - ${campanha.login.email}` : ''}}</h3>
    </div>
    <span v-if="lista && lista.length" class="block text-sm font-medium py-2"><span class="text-blue-500">{{ lista.length }}</span> / {{ total }} </span>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-2 py-1 text-left text-xs font-medium text-gray-500"
                  >
                    IDX
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Telefone
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(item, idx) in lista" :key="item._id">
                  <td class="px-2 py-1">
                    {{ idx + 1 }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    {{ item.telefone }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    {{ item.result && item.temzap && item.enviou ? "Tem zap" : "" }}
                    {{
                      item.result && (!item.temzap || !item.enviou) ? "Não tem zap" : ""
                    }}
                    {{ !item.result ? "Em fila" : "" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <span v-if="lista && lista.length" class="block text-sm font-medium py-2"><span class="text-blue-500">{{ lista.length }}</span> / {{ total }} <span v-if="lista.length < total" @click="addLimit" class="ml-3 underline text-green-500 cursor-pointer">Carregar mais...</span></span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      limit: 1000,
      skip: 0,
      scroll: 0,
      total: 0,
      breadcrumb: [
        { url: "/temzap", value: "Validação Whatsapp" },
        {
          url: `/temzap/detalhes/${this.$route.params.id}`,
          value: "Detalhes da validação",
        },
      ],
      route: "campaigns",
      campanha: null,
      moment: moment,
      lista: [],
    };
  },
  methods: {
     async addLimit(){
      if(this.lista.length + 2000 > this.total) this.limit += this.total - this.lista.length;
      else this.limit += 2000;
      await this.start();
    },
    async start() {
      const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
      if (id) {
          const req = await this.$http.get(`/v1/campanhasLZV2/${id}/contatos?limit=${this.limit}&skip=${this.skip}`);
          this.campanha = req.data.campanha;
          this.lista = req.data.data;
          this.total = req.data.total;
      }
    },
  },
  beforeMount() {
    this.start();
  }
};
</script>
